import { createI18n } from 'vue-i18n'

import deGeneral from './locales/general/general_de.json'
import enGeneral from './locales/general/general_en.json'
import esGeneral from './locales/general/general_es.json'
import frGeneral from './locales/general/general_fr.json'
import nlGeneral from './locales/general/general_nl.json'

import deOffer from './locales/offer/offer_de.json'
import enOffer from './locales/offer/offer_en.json'
import esOffer from './locales/offer/offer_es.json'
import frOffer from './locales/offer/offer_fr.json'
import nlOffer from './locales/offer/offer_nl.json'

import deReview from './locales/review/review_de.json'
import enReview from './locales/review/review_en.json'
import esReview from './locales/review/review_es.json'
import frReview from './locales/review/review_fr.json'
import nlReview from './locales/review/review_nl.json'


function loadLocaleMessages() {
  return {
    de: {
      general: deGeneral,
      offer: deOffer,
      review: deReview,
    },
    en: {
      general: enGeneral,
      offer: enOffer,
      review: enReview,
    },
    es: {
      general: esGeneral,
      offer: esOffer,
      review: esReview,
    },
    fr: {
      general: frGeneral,
      offer: frOffer,
      review: frReview,
    },
    nl: {
      general: nlGeneral,
      offer: nlOffer,
      review: nlReview,
    },
  }
}

export default createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  legacy: false,
  globalInjection: true,
  messages: loadLocaleMessages()
})
