<template>

    <div class="contact-main-div">

        <div class="half-div">
            <div class="left">
                <h1 class="header-contact">{{ t('general.contact[0].contactHeader') }}</h1>

                <div class="contact-info">
                    <div class="info-item">
                        <font-awesome-icon class="info-icon address" icon="map-marker-alt" />
                        <p>Vergezicht complex, 15 Mount Albert Road, Stellenbosch</p>
                    </div>
                    <div class="info-item">
                        <font-awesome-icon class="info-icon email" icon="at" />
                        <p>welcome@rentinstellenbosch.com</p>
                    </div>
                    <div class="info-item">
                        <font-awesome-icon class="info-icon phone" icon="phone" />
                        <p>+27 84 469 3530</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="half-div">
            <div class="right">
                <form @submit.prevent="sendEnquiry">
                    <div class="form-grid">
                        <div class="row">
                            <div>
                                <label for="Name">{{ t('general.contact[0].name') }} *</label><br>
                                <input v-model="Name" type="text" name="Name" :placeholder="namePlaceholder"
                                    :class="{ 'required-field': shouldHighlight(Name) }"><br>
                            </div>
                            <div>
                                <label for="Phone">{{ t('general.contact[0].phone') }}</label><br>
                                <input v-model="Phone" type="tel" name="Phone" :placeholder="phonePlaceholder"><br>
                            </div>
                        </div>
                        <div class="row">
                            <div>
                                <label for="Email">{{ t('general.contact[0].email') }} *</label><br>
                                <input v-model="Email" type="email" name="Email" :placeholder="emailPlaceholder"
                                    :class="{ 'required-field': shouldHighlight(Email) }"><br>
                            </div>
                            <div>
                                <label for="Country">{{ t('general.contact[0].country') }} *</label><br>
                                <input v-model="Country" type="text" name="Country" :placeholder="countryPlaceholder"
                                    :class="{ 'required-field': shouldHighlight(Country) }"><br>
                            </div>
                        </div>
                    </div>

                    <div class="outside">
                        <div>
                            <label style="width: 100%;" for="subject">{{ t('general.contact[0].subject') }}</label><br>
                            <input v-model="Subject" class="wide-input" type="text" name="subject"
                                :placeholder="subjectPlaceholder"><br>
                        </div>
                        <div>
                            <label for="message">{{ t('general.contact[0].message') }}</label><br>
                            <textarea style="font-family: sans-serif" v-model="Message" name="message" rows="6"
                                :placeholder="messagePlaceholder"></textarea><br>
                        </div>
                        <input type="text" name="_honey" style="display:none">
                        <button class="submit-enquiry-btn" type="submit" style="width: 100%;">{{
                            t('general.contact[0].submit') }}</button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMapMarkerAlt, faAt, faPhone } from '@fortawesome/free-solid-svg-icons'

import { useI18n } from 'vue-i18n';

import axios from 'axios';

export default {
    name: 'ContactEnquiryFormSection',
    components: {
        FontAwesomeIcon
    },

    setup() {
        const Name = ref('');
        const Phone = ref('');
        const Email = ref('');
        const Country = ref('');
        const Subject = ref('');
        const Message = ref('');

        const { t, locale } = useI18n();

        const namePlaceholder = ref('');
        const phonePlaceholder = ref('');
        const emailPlaceholder = ref('');
        const countryPlaceholder = ref('');
        const subjectPlaceholder = ref('');
        const messagePlaceholder = ref('');

        const nameRequired = ref('');
        const emailRequired = ref('');

        const submitAttempted = ref(false);

        const loadTranslations = async () => {
            namePlaceholder.value = t('general.contact[0].namePlaceholder');
            phonePlaceholder.value = t('general.contact[0].phonePlaceholder');
            emailPlaceholder.value = t('general.contact[0].emailPlaceholder');
            countryPlaceholder.value = t('general.contact[0].countryPlaceholder');
            subjectPlaceholder.value = t('general.contact[0].subjectPlaceholder');
            messagePlaceholder.value = t('general.contact[0].messagePlaceholder');

            nameRequired.value = t('general.contact[0].nameRequired');
            emailRequired.value = t('general.contact[0].mailRequired');
        };

        const sendEnquiry = () => {
            submitAttempted.value = true;

            const errors = [];
            if (!Name.value.trim()) {
                namePlaceholder.value = t('general.contact[0].nameRequired');
                errors.push("Name");
            }
            if (!Email.value.trim()) {
                emailPlaceholder.value = t('general.contact[0].emailRequired');
                errors.push("Email");
            }
            if (!Country.value.trim()) {
                countryPlaceholder.value = t('general.contact[0].countryRequired');
                errors.push("Country");
            }

            // If there are errors prevent form submission
            if (errors.length > 0) {
                return;
            }

            const emailStyleForm = "table"
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            axios.post(process.env.VUE_APP_EMAILER_URL, {
                _template: emailStyleForm,
                Name: Name.value,
                Email: Email.value,
                Phone: Phone.value,
                Country: Country.value,
                _subject: Subject.value,
                Message: Message.value,
            })
                .then(response => {
                    console.log(response);
                    clearFields();
                    submitAttempted.value = false;
                })
                .catch(error => console.log(error));

        };

        const clearFields = () => {
            Name.value = ""
            Phone.value = ""
            Email.value = ""
            Country.value = ""
            Subject.value = ""
            Message.value = ""
        }

        const shouldHighlight = (field) => {
            return submitAttempted.value && !field.trim();
        };

        onMounted(() => {
            loadTranslations();
        });

        watch(() => locale.value, () => {
            loadTranslations();
        });

        return {
            sendEnquiry,
            Name,
            Phone,
            Email,
            Country,
            Subject,
            Message,
            t,

            namePlaceholder,
            phonePlaceholder,
            emailPlaceholder,
            countryPlaceholder,
            subjectPlaceholder,
            messagePlaceholder,

            nameRequired,
            emailRequired,
            loadTranslations,
            shouldHighlight
        }
    },
    created() {
        library.add(faMapMarkerAlt, faAt, faPhone)
    }
}
</script>


<style scoped>
.required-field {
    background-color: rgb(255, 199, 199);
    border: 1px solid red;
}

.contact-main-div {
    display: flex;
    margin: 90px 12% 80px 12%;
    align-items: stretch;
}

.half-div {
    display: flex;
    flex: 1;
}

.left {
    flex: 1;
    height: auto;
    text-align: center;
}

.right {
    flex: 2;
    height: auto;
    text-align: center;
}


/** ---------- Contact ---------- */
.header-contact {
    color: rgb(43, 66, 80);
    margin-bottom: 20px;
    font-size: 3.0em;
    font-weight: 800;

    text-align: start;
    margin: 0% 20% 3% 0%;
}

h1 {
    padding: 0;
    margin: 0;
}

.contact-info {
    display: flex;
    flex-direction: column;
    margin: 0% 10% 0% 0%;
    height: 250px;
}

.info-item {
    display: flex;
    align-items: center;
    height: 50px;
    margin: 0% 0% 2% 0%;
}

.info-icon {
    margin-right: 5px;
    height: 25px;
    color: rgb(43, 66, 80);
}

.info-item p {
    text-align: left;
    color: rgb(43, 66, 80);
}


/** ---------- Form ---------- */
.form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    width: auto;
}

.form-grid .row {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    width: auto;
}

textarea {
    resize: none;
    width: 100%;
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid gray;
}

textarea:hover {
    outline: 2px solid gray;
}

.wide-input {
    width: 100%;
    height: 40px;
}

input {
    margin-right: 100px;
    width: 100%;
    margin: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 40px;

    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid gray;
}

.outside div {
    padding-top: 20px;
}

input:hover {
    outline: 2px solid gray;
}

label {
    color: rgb(43, 66, 80);
    font-weight: 600;
    text-align: left;
    display: block;
    margin: 0px;
}


.submit-enquiry-btn {
    height: 50px;
    background-color: #2c3e50;
    border: none;
    color: white;
    font-size: large;
    margin-top: 30px;
}

.submit-enquiry-btn:hover {
    background-color: #627a93;
}

@media screen and (max-width: 992px) {
    .contact-main-div {
        margin: 0% 5% 80px 5%;
    }

    .contact-info {
        border-radius: 10px;
        margin: 0% 10% 0% 0%;
    }

    .header-contact {
        margin-bottom: 20px;
        font-size: 2.0em;
        text-align: start;
        margin-left: 0%;
        margin-right: 0%;
    }
}

@media screen and (max-width: 600px) {
    .contact-main-div {
        flex-direction: column;
    }

    .header-contact {
        margin-bottom: 20px;
        text-align: center;
        font-size: 1.8em;
    }

    .contact-info {
        margin: 0% 0% 0% 0%;
    }
}
</style>