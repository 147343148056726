<template>
    <div class="default-nav">
        <nav class="nav-bar">
            <a class="nav-link" @click.prevent="scrollToSection('#listing')">{{ t('general.nav[0].listing') }}</a>
            <a class="nav-link" @click.prevent="scrollToSection('#accreditation')">{{ t('general.nav[0].accreditation')
                }}</a>
            <a class="nav-link" @click.prevent="scrollToSection('#offer')">{{ t('general.nav[0].offer') }}</a>
            <a class="nav-link" @click.prevent="scrollToSection('#reviews')">{{ t('general.nav[0].reviews') }}</a>
            <a class="nav-link" @click.prevent="scrollToSection('#contact')">{{ t('general.nav[0].contact') }}</a>
        </nav>
    </div>

    <div class="drawer-menu">
        <!-- Menu icon -->
        <div class="menu-header-wrapper">
            <div class="menu-icon">
                <font-awesome-icon icon="bars" @click="toggleDrawer" class="menu-btn" />
            </div>
        </div>

        <!-- Side panel -->
        <div class="side-panel" :class="{ 'panel-open': isDrawerOpen }">
            <div class="panel-header">
                <p class="close-text">{{ t('general.nav[0].close') }}</p>
                <p class="close-icon" @click="toggleDrawer">X</p>
            </div>

            <div class="line-break"></div>

            <nav class="menu-drawer">
                <a class="menu-link" id="#listing" @click.prevent="handleMenuLinkClick('#listing')">{{
                    t('general.nav[0].listing') }}</a>
                <a class="menu-link" id="#accreditation" @click.prevent="handleMenuLinkClick('#accreditation')">{{
                    t('general.nav[0].accreditation') }}</a>
                <a class="menu-link" id="#offer" @click.prevent="handleMenuLinkClick('#offer')">{{
                    t('general.nav[0].offer') }}</a>
                <a class="menu-link" id="#reviews" @click.prevent="handleMenuLinkClick('#reviews')">{{
                    t('general.nav[0].reviews') }}</a>
                <a class="menu-link" id="#contact" @click.prevent="handleMenuLinkClick('#contact')">{{
                    t('general.nav[0].contact') }}</a>
            </nav>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { useI18n } from 'vue-i18n';

library.add(faBars);

export default {
    name: 'NavSection',
    components: {
        FontAwesomeIcon
    },
    setup() {
        const isDrawerOpen = ref(false);

        const { t } = useI18n();

        const scrollToSection = (sectionId) => {
            const section = document.querySelector(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
                isDrawerOpen.value = false;
            }
        };

        const toggleDrawer = () => {
            isDrawerOpen.value = !isDrawerOpen.value;
        };


        const handleMenuLinkClick = async (sectionId) => {
            // Change color for the clicked menu link
            const clickedLink = document.getElementById(sectionId);
            if (clickedLink) {
                clickedLink.style.color = 'rgb(248, 140, 32)';
            }

            await delay(200);
            toggleDrawer();

            // Reset color for all menu links
            const menuLinks = document.querySelectorAll('.menu-link');
            menuLinks.forEach(menuLink => {
                menuLink.style.color = 'rgb(43, 66, 80)';
            });

            scrollToSection(sectionId);
        };

        const delay = (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        };

        return {
            isDrawerOpen,
            toggleDrawer,
            scrollToSection,
            handleMenuLinkClick,
            t
        };
    }
}
</script>


<style>
/* Default nave bar styling */
.nav-bar {
    background-color: white;
    padding: 30px 0% 25px 0%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30px, auto));
    justify-content: space-evenly;
}

.nav-link {
    text-align: center;
    background-color: white;

    font-size: 1rem;
    font-weight: 600;
    font-feature-settings: normal;
    color: rgb(43, 66, 80);
    cursor: pointer;
}

.nav-link:hover {
    color: rgb(248, 140, 32);
}


/*Drawer menu styling */
.menu-header-wrapper {
    display: grid;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin: 25px 0px 10px 20px;
}

.menu-btn {
    width: 30px;
    height: 30px;
    padding-bottom: 5px;
}

.side-panel {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: white;
    transition: width 0.3s ease-out;
    z-index: 1000;
    overflow-x: hidden;
}

.panel-open {
    width: 60vw;
}

.menu-drawer {
    padding: 40px 20px 0px 20px;
}

.panel-header {
    margin-left: 30px;
    margin-right: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.panel-header p {
    font-size: 16px;
    font-weight: 600;
    font-feature-settings: normal;
    color: rgb(43, 66, 80);
    margin-top: 30px;
    margin-bottom: 30px;
}

.close-text {
    pointer-events: none;
}

.close-icon {
    cursor: pointer;
}

.line-break {
    width: auto;
    height: 0.5px;
    border-top: 0.01px solid #DADDE1;
    margin: 0px 30px 0px 30px;
}

.menu-link {
    display: block;
    padding: 15px 10px;
    text-decoration: none;

    font-size: 16px;
    font-weight: 600;
    font-feature-settings: normal;
    color: rgb(43, 66, 80);
    cursor: pointer;
}

.menu-link:hover {
    color: rgb(248, 140, 32);
}

@media screen and (min-width: 992px) {
    .drawer-menu {
        display: none;
    }
}

@media screen and (max-width: 992px) {
    .nav-bar {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .nav-bar {
        display: none;
    }

    .menu-header-wrapper {
        margin: 15px 0px 10px 15px;
    }
}
</style>