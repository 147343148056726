<template>
  <HeroSection />
  <div id="listing" class="page-section">
    <ListingSection />
  </div>
  <div id="accreditation" class="page-section">
    <AccreditationBanner />
  </div>
  <div id="offer" class="page-section">
    <OfferSection />
  </div>
  <div id="reviews" class="page-section">
    <ReviewSection />
  </div>
  <div id="contact" class="page-section">
    <ContactEnquiryFormSection />
  </div>
  <FooterSection />
</template>

<script>
import HeroSection from '/src/components/HeroSection.vue'
import ListingSection from '/src/components/ListingSection.vue'
import AccreditationBanner from '/src/components/AccreditationBanner.vue'
import OfferSection from '/src/components/OfferSection.vue'
import ContactEnquiryFormSection from '/src/components/ContactEnquiryFormSection.vue'
import ReviewSection from '/src/components/ReviewSection.vue'
import FooterSection from '/src/components/FooterSection.vue'


export default {
  name: 'App',
  components: {
    HeroSection,
    ListingSection,
    AccreditationBanner,
    OfferSection,
    ContactEnquiryFormSection,
    ReviewSection,
    FooterSection
  }
}
</script>

<style>
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Open Sans", Sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#accreditation {
  padding-top: 80px;
  padding-bottom: 80px;
}
</style>