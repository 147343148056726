<template>
    <div class="footer-main-div">
        <div class="footer-info">
            <p>RentInStellenbosch.com</p>

            <span style="display: block; margin-bottom: 10px;"> by <a href="https://drakkentech.co.za/"
                    target="_blank">DrakkenTech</a> </span>
            <span>Photo by <a href="https://unsplash.com/@naassomz1" target="_blank"> Naassom Azevedo</a> on
                Unsplash</span>
        </div>
    </div>
</template>

<script>

export default {
    name: 'FooterSection',
}
</script>

<style scoped>
.footer-main-div {
    background-color: rgb(168, 168, 168);
    height: auto;
    border: 0.01px solid gray;
    padding-bottom: 10px;
}

.footer-info {
    text-align: center;
    cursor: pointer;
}

.footer-info p {
    margin-bottom: 0%;
    font-weight: 800;
    font-size: 1em;
}

@media (max-width: 768px) {
    .footer-main-div {
        height: auto;
        padding: 10px;
    }

    .footer-info h1 {
        font-size: 14px;
    }
}
</style>