<template>
    <div class="offer-main-div">
        <div class="offer-header">
            <h1>{{ t('general.offer[0].offerHeader') }}:</h1>
            <img class="offer-guide-image" src="images/whatWeOffer/pointer_click.png">
            <span class="offer-guide-text">{{ t('general.offer[0].offerGuide') }}</span>
        </div>

        <div class="offer-container">
            <div v-for="item in items" :key="item.id" class="offer-card" @click="showModel(item, $event)">
                <span><img class="card-image" :src="item.iconSrc"></span>
                <p class="card-description">{{ item.captionText }}</p>
            </div>

            <div v-if="openModel" class="info-model">
                <div class="scroll">
                    <span @click="hidePopup" class="close-model">×</span>
                    <span @click="hidePopup" class="model-header">{{ hoveredItem.captionText }}</span>

                    <span v-for="(text, index) in hoveredItem.popupText" :key="index" class="model-text">
                        <p class="model-text">{{ text }}</p>
                        <div v-if="hoveredItem.id == 11" class="map-container">
                            <div v-for="map in maps" :key="map.id" class="half-width" @click="toggleMapSize(map.id)"
                                :class="{ 'active': activeMap === map.id }">
                                <img :src="map.src" :alt="map.alt">
                            </div>
                        </div>
                    </span>

                </div>
            </div>
        </div>

    </div>

</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
    name: 'OfferSection',
    setup() {
        const hoveredItem = ref(null);
        const openModel = ref(false);
        const activeMap = ref(null);
        const offerItems = ref([]);
        const items = ref([])

        const { t, locale } = useI18n();

        const loadImports = async () => {
            const language = locale.value;
            offerItems.value = await import(`../../src/locales/offer/offer_${language}.json`);
            items.value = offerItems.value.default.offers
        };

        const maps = [
            { id: 1, src: 'images/whatWeOffer/maps/map_1.png', alt: 'map 1' },
            { id: 2, src: 'images/whatWeOffer/maps/map_2.png', alt: 'map 2' }
        ];

        const showModel = (item, event) => {
            event.stopPropagation();
            hoveredItem.value = item;
            openModel.value = true;
        };

        const hidePopup = () => {
            hoveredItem.value = null;
            openModel.value = false;
            activeMap.value = null;
        }

        const toggleMapSize = (mapId) => {
            activeMap.value = activeMap.value === mapId ? null : mapId;
        };

        // Close info-model when clicking outside of model area
        const handleClickOutside = (event) => {
            const infoModel = document.querySelector('.info-model');
            if (infoModel && !infoModel.contains(event.target)) {
                hidePopup();
            }
        };

        onMounted(() => {
            loadImports();
            document.addEventListener('click', handleClickOutside);
        });

        watch(() => locale.value, () => {
            loadImports();
        });

        return {
            items,
            hoveredItem,
            showModel,
            hidePopup,
            openModel,
            maps,
            activeMap,
            toggleMapSize,
            t,
            loadImports
        }
    }
}
</script>

<style scoped>
.offer-main-div {
    margin: 0% 12% 90px 12%;
    height: auto;
    color: rgb(43, 66, 80);
}

.offer-header h1 {
    font-size: 2.9em;
    font-weight: 800;
    color: rgb(43, 66, 80);
}

.offer-guide-image {
    height: auto;
    width: 30px;
    margin-right: 5px;
}

.offer-header {
    margin-bottom: 20px;
}

.offer-guide-text {
    color: rgb(43, 66, 80);
    font-size: 16px;
}

.offer-container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    margin: auto;
    width: 90%;
    margin-top: 30px;
}

.offer-card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    text-align: center;
    cursor: pointer;
}

.offer-card:hover {
    transform: scale(1.09);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card-image {
    padding-top: 20px;
    height: auto;
    width: 100px;
    align-items: center;
    justify-content: center;
}

.card-description {
    font-size: large;
    font-weight: 500;
    text-align: center;
}

.info-model {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(43, 66, 80);
    width: 60%;
    height: auto;
    padding: 35px;
    border-radius: 10px;
    z-index: 100;
}

.model-header {
    display: block;
    font-size: 1.5em;
    text-align: center;
    margin: 5px;
    color: white;
    text-decoration: underline;
}

.close-model {
    display: block;
    position: absolute;
    font-size: 2.5em;
    top: 0px;
    right: 10px;
    color: white;
    cursor: pointer;
}

.close-model:hover {
    color: rgb(248, 140, 32);
}

.model-text p {
    margin: 20px;
    line-height: 25px;
    margin-top: 25px;
    color: white;
}

.map-container {
    display: flex;
    cursor: pointer;
}

.half-width {
    flex: 1;
    margin-right: 10px;
    transition: flex-grow 0.5s ease-in-out;
}

.half-width:last-child {
    margin-right: 0;
}

.half-width img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.half-width.active {
    flex-grow: 5;
    z-index: 1;
}

@media screen and (max-width: 992px) {
    .offer-main-div {
        margin: 0% 0% 90px 0%;
    }

    .offer-header {
        font-size: 0.7em;
        margin-left: 4%;
    }

    .offer-header img {
        content: url('/public/images/whatWeOffer/finger_click.png');
    }
}

@media screen and (max-width: 1370px) and (orientation: landscape) {
    .offer-header img {
        content: url('/public/images/whatWeOffer/finger_click.png');
    }
}

@media screen and (max-width: 600px) {
    .offer-main-div {
        margin: 0% 0% 80px 0%;
    }

    .offer-header h1 {
        font-size: 2.8em;
        margin-left: 2.5%;
    }

    .offer-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }

    .card-image {
        width: 90px;
    }

    .card-description {
        font-size: large;
        font-size: 1.0em;
    }

    .info-model {
        position: fixed;
        top: 0%;
        padding: 10px;
        transform: translateX(-50%);
        width: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .scroll {
        overflow-y: auto;
    }

    .model-text p {
        margin-top: 30px;
    }
}
</style>