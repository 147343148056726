<template>
    <div class="hero-div">

        <div class="hero-left-div">
            <div class="nav-section">
                <NavSection />
            </div>

            <div class="text-container">
                <div class="text-box small-text">
                    {{ t('general.hero[0].motto') }}
                </div>

                <div class="text-box big-text">
                    {{ t('general.hero[0].heroHeader') }}
                </div>
            </div>
        </div>

        <div class="hero-right-div"></div>

        <LanguageSelector />
    </div>
</template>

<script>
import NavSection from '/src/components/NavSection.vue'
import LanguageSelector from '/src/components/LanguageSelector.vue'

import { useI18n } from 'vue-i18n';

export default {
    name: 'HeroSection',
    components: {
        NavSection,
        LanguageSelector
    },
    setup() {
        const { t } = useI18n();

        return {
            t
        }
    }
}
</script>

<style>
.nav-section {
    position: relative;
    top: 0px;
    width: 100%;
}

.hero-div {
    display: flex;
    margin-bottom: 80px;
}

.hero-right-div {
    width: 50%;
    height: 90vh;
    background-image: url('/public/images/hero/naassom-azevedo-Q_Sei-TqSlc-unsplash.jpg');
    background-size: cover;
    background-position: center;
}

.hero-left-div {
    width: 50%;
}

.text-container {
    margin-top: 30%;
    margin-bottom: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start
}

.text-box {
    margin-left: 8%;
    align-items: center;
    text-align: left;
    margin-right: 10%;
    margin-bottom: 5%;
}

.small-text {
    font-size: 1.em;
    font-weight: 600;
    color: gray;
}

.big-text {
    font-size: 3.5em;
    font-weight: 800;
    color: rgb(43, 66, 80);
    line-height: 60px;
}

@media screen and (max-width: 992px) {
    .hero-left-div {
        width: 50%;
        height: 60vh;
    }

    .hero-right-div {
        width: 50%;
        height: 60vh;
    }

    .text-container {
        margin-top: 50%;
    }


    .text-box {
        margin-left: 6%;
    }

    .small-text {
        font-size: 0.8em;
    }

    .big-text {
        font-size: 3.0em;
    }
}

@media screen and (max-width: 600px) {
    .hero-right-div {
        display: none;
    }

    .hero-left-div {
        height: 50vh;
        width: 100%;
    }

    .text-box {
        margin-top: 0%;
    }

    .text-container {
        margin-top: 40%;
    }
}
</style>