<template>
    <div class="listing-main-div">
        <div class="listing-text-box header-text">
            <span class="listing-title">{{ t('general.listing[0].listingHeader') }}</span>

            <div class="popover-wrapper">
                <img class="listing-key" src="/images/listingIcon/key.png">
                <div class="popover-content">
                    <!-- Content of the popover -->
                    <span class="key-icon-header">{{ t('general.listing[0].key[0].keyHeader') }}:</span>
                    <span class="key-icon"><img src="/images/listingIcon/furnished.png" alt="furnished"
                            class="icon-img" />{{ t('general.listing[0].key[0].furnished') }}</span>
                    <span class="key-icon"><img src="/images/listingIcon/garden.png" alt="garden" class="icon-img" />
                        {{ t('general.listing[0].key[0].garden') }}</span>
                    <span class="key-icon"><img src="/images/listingIcon/linen.png" alt="linen" class="icon-img" />
                        {{ t('general.listing[0].key[0].linen') }}</span>
                    <span class="key-icon"><img src="/images/listingIcon/oven.png" alt="oven" class="icon-img" />
                        {{ t('general.listing[0].key[0].oven') }}</span>
                    <span class="key-icon"><img src="/images/listingIcon/washing.png" alt="washing" class="icon-img" />
                        {{ t('general.listing[0].key[0].washing') }}</span>
                    <span class="key-icon"><img src="/images/listingIcon/wifi.png" alt="WiFi" class="icon-img" />
                        {{ t('general.listing[0].key[0].wifi') }}</span>
                </div>

            </div>
        </div>

        <!-- Tile -->
        <div class="grid-container">
            <div v-for="(listing, index) in paginatedListings" :key="index" class="tile"
                @click="toggleComponent(listing)">
                <div class="subpart-1" :style="{ backgroundImage: 'url(' + listing.image + ')' }">
                    <div class="listing-status-div">
                        <span v-if="listing.availability == 'available'">{{
                            t('general.listing[0].availability[0].available') }}</span>
                        <span v-if="listing.availability == 'reserved'">{{
                            t('general.listing[0].availability[0].reserved') }}</span>
                        <span v-if="listing.availability == 'unavailable'">{{
                            t('general.listing[0].availability[0].unavailable') }}</span>

                    </div>
                </div>

                <div class="subpart-2" style="padding-top: 8px;">
                    <span class="list-price">R {{ formatNumber(listing.price) }} /{{ t('general.listing[0].perMonth')
                        }}</span>
                    <span class="list-name">{{ listing.name }} {{ listing.unit }}</span>
                </div>

                <div class="subpart-3">
                    <span v-if="listing.icons[0].furnished" class="icon"><img src="/images/listingIcon/furnished.png"
                            alt="WiFi" class="icon-img" /></span>
                    <span v-if="listing.icons[0].garden" class="icon"><img src="/images/listingIcon/garden.png"
                            alt="WiFi" class="icon-img" /></span>
                    <span v-if="listing.icons[0].linen" class="icon"><img src="/images/listingIcon/linen.png" alt="WiFi"
                            class="icon-img" /></span>
                    <span v-if="listing.icons[0].oven" class="icon"><img src="/images/listingIcon/oven.png" alt="WiFi"
                            class="icon-img" /></span>
                    <span v-if="listing.icons[0].washing" class="icon"><img src="/images/listingIcon/washing.png"
                            alt="WiFi" class="icon-img" /></span>
                    <span v-if="listing.icons[0].wifi" class="icon"><img src="/images/listingIcon/wifi.png" alt="WiFi"
                            class="icon-img" /></span>
                </div>
            </div>
        </div>

        <div class="paginator-div">
            <Paginator v-model:first="first" style="" :rows="rows" :totalRecords="totalRecords"></Paginator>
        </div>

        <!-- Image gallery to show on tile click -->
        <div v-if="selectedTile && images != undefined" class="selected-tile-gallery">
            <Galleria class="imageGallery" v-model:visible="displayBasic" :value="images"
                :responsiveOptions="responsiveOptions" :numVisible="5" containerStyle="max-width: 600px"
                :circular="true" :fullScreen="true" :showItemNavigators="true" :showThumbnailNavigators="false"
                :showThumbnails="true" :showIndicators="false" style="margin-bottom: 10px">
                <template #item="slotProps">
                    <img :src="slotProps.item.itemImageSrc" :alt="slotProps.item.alt"
                        style="width: 100%; display: block" />
                </template>
                <template #thumbnail="slotProps">
                    <img :src="slotProps.item.itemImageSrc" :alt="slotProps.item.alt"
                        style="width: 50%; display: block; justify-content: center;" />
                </template>
            </Galleria>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import Galleria from 'primevue/galleria';
import Paginator from 'primevue/paginator';
import axios from "axios";

import { useI18n } from 'vue-i18n';

export default {
    name: 'ListingSection',
    components: {
        Galleria,
        Paginator
    },
    setup() {
        const listings = ref([]);
        const selectedTile = ref(null);

        const images = ref();
        const displayBasic = ref(false);
        const totalRecords = ref();
        const rows = ref();
        const first = ref(0);

        const responsiveOptions = ref([
            {
                breakpoint: '992px',
                numVisible: 4,
            },

            {
                breakpoint: '600px',
                numVisible: 3,
            },
        ]);

        const { t } = useI18n();

        const loadListings = async () => {
            var listingsData = {}

            var resolvedListings = []

            const response = await axios.get("/listingConfig.json");
            listingsData = response.data;
            totalRecords.value = listingsData.items.length
            rows.value = listingsData.grid_number

            listingsData.items.forEach(listing => {

                console.log("listing details are: " + JSON.stringify(listing))

                resolvedListings.push({
                    unit: listing.unit,
                    name: listing.name,
                    price: listing.price,
                    availability: listing.availability,
                    icons: listing.icons,
                    image: listing.listingMainImage,
                    allImages: listing.otherImages,
                });
            });

            resolvedListings.sort((a, b) => {
                const availabilityA = a.availability.toLowerCase();
                const availabilityB = b.availability.toLowerCase();

                if (availabilityA === "available") return -1;
                if (availabilityA === "reserved" && availabilityB !== "available") return -1;
                return 1;
            });

            listings.value = resolvedListings;
        };

        const selectedListingsImages = (listing) => {
            images.value = listing.allImages
        }

        const toggleComponent = (tile) => {
            selectedTile.value = tile;
            displayBasic.value = true;
            selectedListingsImages(tile)
        };

        const formatNumber = (value) => {
            const [whole, decimal] = String(value).split('.');
            const formattedWhole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return decimal ? `${formattedWhole},${decimal}` : formattedWhole;
        };

        const paginatedListings = computed(() => {
            const startIndex = first.value;
            const endIndex = startIndex + rows.value;
            return listings.value.slice(startIndex, endIndex);
        });

        onMounted(() => {
            loadListings();
        });

        return {
            listings,
            selectedTile,
            toggleComponent,
            images,
            responsiveOptions,
            selectedListingsImages,
            displayBasic,
            formatNumber,
            t,
            totalRecords,
            first,
            rows,
            paginatedListings
        };
    },
};
</script>

<style scoped>
.listing-status-div {
    background-color: #BCE4E8;
    min-width: 35%;
    max-width: 50%;
    text-align: center;
    position: relative;
    left: 45%;
    top: 5%;
    padding: 2%;
    border-radius: 10px;
    color: rgb(43, 66, 80);
}

.paginator-div {
    justify-content: end;
    margin-top: 50px;
    width: 100%;
}

.listing-main-div {
    margin: 0% 12% 0% 12%;
    display: grid;
}

.listing-text-box {
    align-items: center;
    text-align: left;
}

.header-text {
    font-size: 3.0em;
    font-weight: 800;
    color: rgb(43, 66, 80);
    line-height: 60px;
    margin-bottom: 3%;
}

.listing-key {
    width: 25px;
    height: auto;
    margin-left: 10px;
    vertical-align: middle;
}

.popover-wrapper {
    position: relative;
    display: inline-block;
}

.popover-content {
    display: flex;
    flex-direction: column;
    width: 200px;
    position: absolute;
    bottom: -200%;
    left: 150%;
    background-color: white;

    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    display: none;
    border-radius: 10px;
    z-index: 1;
}

.key-icon-header {
    margin-left: 15px;
    font-size: 20px;
    justify-self: center;
    text-decoration: underline;
}

.key-icon {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-left: 15px;
    height: 40px;
}

.key-icon img {
    margin-right: 15px;
}

.popover-wrapper:hover .popover-content {
    display: block;
}

/**---------- Listing Grid ---------- */

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
}

.tile {
    background-color: #F5F5F5;
    border-radius: 8px;
    height: 380px;
    display: flex;
    flex-direction: column;
    width: auto;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    cursor: pointer;
}

.tile:hover {
    transform: scale(1.07);
    background-color: white
}

.subpart,
.subpart-1,
.subpart-2,
.subpart-3 {
    border: 0.01px solid #d0d4d9;
}

.subpart {
    background-size: contain;
    background-position: center;
}

.subpart-1 {
    height: 55%;
    border-top: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    background-size: 100% 100%;
    background-position: center;
}

.subpart-2 p {
    display: block;
    font-size: larger;
    font-weight: 500;
    color: rgb(43, 66, 80);

    margin-left: 5px;
    margin-top: 0%;
}


.subpart-2 span {
    display: block;
    color: rgb(43, 66, 80);

    margin-left: 5px;
    margin-top: 0%;
}

.list-price {
    font-size: larger;
    font-weight: 600;
    padding: 2%;
}

.list-name {
    padding: 2%;
}

.subpart-2 {
    height: 25%;
}

.subpart-3 {
    flex: 1;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}

.icon {
    width: 25%;
    text-align: center;
}

.icon-img {
    width: 25px;
    height: auto;
}

@media screen and (max-width: 1200px) {
    .grid-container {
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
    }
}

@media screen and (max-width: 992px) {
    .listing-main-div {
        margin: 0% 0% 0% 0%;
    }

    .listing-text-box {
        margin-left: 4%;
    }

    .listing-key {
        width: 20px;
    }

    .header-text {
        font-size: 2.0em;
    }

    .grid-container {
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;

        margin-left: 6%;
        margin-right: 6%;
    }

    .tile {
        height: 380px;
        display: flex;
        flex-direction: column;
        width: 300px;
    }
}

@media screen and (max-width: 600px) {
    .listing-text-box {
        margin-left: 6%;
    }

    .grid-container {
        grid-template-columns: 1fr;
        justify-items: center;
    }

    .tile {
        height: 380px;
        display: flex;
        flex-direction: column;
        width: 300px;
    }

    .listing-main-div {
        margin-top: 20px;
    }

    .imageGallery {
        display: none;
    }

    .paginator-div {
        justify-content: end;
        padding-top: 30px;
        width: 100%;
        margin-left: 0%;
    }
}
</style>