<template>
    <div class="language-div" style="">

        <Dropdown v-model="selectedLanguage" :options="languages" optionLabel="language" placeholder="Language"
            @change="changeLanguage">

            <template #value="slotProps">
                <div v-if="slotProps.value">
                    <img :alt="slotProps.value.label" :src="slotProps.value.icon"
                        style="width: 18px; margin-right: 5px;" />
                    <span>{{ slotProps.value.language }}</span>
                </div>
                <span v-else style="display: flex; align-items: center;">
                    {{ slotProps.placeholder }}
                </span>
            </template>
            <template #option="slotProps">
                <div style="display: flex; align-items: center;">
                    <img :alt="slotProps.option.label" :src="slotProps.option.icon"
                        style="width: 18px; margin-right: 5px;" />
                    <div>{{ slotProps.option.language }}</div>
                </div>
            </template>

        </Dropdown>
    </div>
</template>

<script>
import { ref } from 'vue';
import Dropdown from 'primevue/dropdown';
import { useI18n } from 'vue-i18n';

export default {
    name: 'languageSelector',
    components: {
        Dropdown
    },
    setup() {

        const selectedLanguage = ref();
        const languages = ref([
            { language: 'English', code: "en", icon: 'images/languages/english.png' },
            { language: 'German', code: "de", icon: 'images/languages/german.png' },
            { language: 'Dutch', code: "nl", icon: 'images/languages/dutch.png' },
            { language: 'Spanish', code: "es", icon: 'images/languages/spanish.png' },
            { language: 'French', code: "fr", icon: 'images/languages/french.png' },
        ]);

        const { locale } = useI18n();

        const changeLanguage = () => {
            locale.value = selectedLanguage.value.code;
        };

        return {
            selectedLanguage,
            languages,
            changeLanguage
        }
    }
}

</script>


<style>
.language-div {
    position: absolute;
    top: 3%;
    left: 55%;
}

@media screen and (max-width: 1024px) {
    .language-div {
        top: 1.5%;
        left: 55%;
    }
}

@media screen and (max-width: 992px) {
    .language-div {
        top: 2%;
        left: 55%;
    }
}

@media screen and (max-width: 600px) {
    .language-div {
        top: 2%;
        left: 50%;
    }
}
</style>