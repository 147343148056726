import { createApp } from 'vue';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/aura-light-blue/theme.css'

import i18n from '@/i18.js'

const app = createApp(App);
app.use(i18n);
app.use(PrimeVue);

app.mount('#app');
