<template>
    <div class="accreditation-main-div">
        <div class="accreditation-info" @click="downloadFile">
            <div class="stellies-icon">
                <img src="images/other/stellies-icon.png">
            </div>
            <h1>{{ t('general.accreditation') }}</h1>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';

import { useI18n } from 'vue-i18n';

export default {
    name: 'AccreditationBanner',

    setup() {
        const accreditationInfo = ref(null);

        const downloadFile = () => {
            const downloadLink = document.createElement('a');
            downloadLink.href = '/documents/Gmail - FW_ Accreditation Status 2024.pdf';
            downloadLink.download = 'Rent_In_Stellenbosch_Accreditation_Status_2024.pdf';
            downloadLink.click();
        };

        const { t } = useI18n();

        return {
            accreditationInfo,
            downloadFile,
            t
        };
    }
};
</script>

<style scoped>
.accreditation-main-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin: 0% 3% 0% 3%;
}

.accreditation-info {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 20px;
    background-color: #641536;
    color: white;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    cursor: pointer;
}


.accreditation-info:hover {
    background-color: #8a0e42;
}

.stellies-icon img {
    border-radius: 10px;
    max-width: 100%;
    max-height: 4rem;
    margin-right: 20px;
    margin-top: 0%;
}

.accreditation-info h1 {
    margin: 0;
    font-weight: 100;
}


@media only screen and (max-width: 992px) {
    .accreditation-info h1 {
        font-size: 1.6em;
    }
}

@media only screen and (max-width: 600px) {
    .stellies-icon img {
        max-height: 35px;
        margin-right: 10px;
    }

    .accreditation-info h1 {
        font-size: 0.9em;
    }

    .accreditation-info {
        border-radius: 10px;
    }
}
</style>