<template>
    <div class="review-main-div">
        <div class="review-header">
            <h1>{{ t('general.reviews[0].reviewHeader') }}</h1>
        </div>

        <Carousel :autoplay="2500" :wrap-around="true">
            <Slide v-for="review in reviews" :key="review.id" class="carousel__container">
                <div style="height: 400px; display: flex; flex-direction: column; justify-content: center; align-items: center;"
                    class="carousel__item">
                    <div>
                        <p class="review-text" style=" display: block; margin: auto;">
                            <img class="right-quote" src="images/other/quote-right.png" alt="quote right">
                            {{ review.review }}
                        </p>
                    </div>
                    <div>
                        <p class="review-name">{{ review.name }}</p>
                        <Rating v-model="review.stars" readonly :cancel="false"
                            style="padding: 20px; display: block;" />
                    </div>
                </div>
            </Slide>

            <template #addons>
                <Pagination style="padding: 0%" />
                <Navigation />
            </template>
        </Carousel>
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

import { useI18n } from 'vue-i18n';

import Rating from 'primevue/rating';

export default {
    name: 'ReviewSection',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        Rating
    },
    setup() {
        const reviews = ref([])
        const reviewItems = ref([]);

        const { t, locale } = useI18n();

        const loadReviews = async () => {
            const language = locale.value;
            reviewItems.value = await import(`../../src/locales/review/review_${language}.json`);

            reviews.value = reviewItems.value.default.reviews
        };

        onMounted(() => {
            loadReviews();
        });

        watch(() => locale.value, () => {
            loadReviews();
        });

        return {
            reviews,
            reviewItems,
            t,
            locale

        };
    },
};
</script>

<style>
.right-quote {
    display: inline;
    width: 50px;
    height: auto;
    position: relative;
}

.review-main-div {
    margin: 0% 12% 80px 12%;
    z-index: -1;
}

.review-header h1 {
    font-size: 2.9em;
    font-weight: 800;
    color: rgb(43, 66, 80);
    text-align: center;
}

.review-header {
    margin-bottom: 20px;
}

.review-text {
    font-size: 18px;
    padding: 10px;
    align-items: center;
    width: 80%;
    max-height: 80%;
    height: auto;
}

.review-name {
    font-size: 20px;
    font-weight: 600;
}

.carousel__item {
    min-height: 200px;
    width: 100%;
    box-shadow: rgba(9, 30, 66, 0.35) 0px 1px 1px, rgba(9, 30, 66, 0.19) 0px 0px 1px 1px;
    background-color: white;
    color: rgb(43, 66, 80);
    font-size: 20px;
    border-radius: 8px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel__slide {
    padding: 10px;
}

.carousel__prev,
.carousel__next {
    color: rgb(43, 66, 80);
    outline: none;

    box-sizing: content-box;
    height: auto;
}

.carousel__next {
    margin-right: 15px;
}

.carousel__prev {
    margin-left: 15px;
}

@media screen and (max-width: 992px) {
    .review-main-div {
        margin: 0% 5% 80px 5%;
    }

    .review-header {
        font-size: 0.7em;
        margin-left: 4%;
    }

    .right-quote {
        width: 45px;
    }
}

@media screen and (max-width: 600px) {
    .review-header h1 {
        font-size: 2.8em;
        margin-left: 2.5%;
    }

    .review-text {
        font-size: 16px;
    }

    .review-name {
        font-size: 18px;
    }

    .right-quote {
        width: 35px;
    }
}
</style>